import React from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../../app/components/styled';
import { portalPreviewVideo } from '../../../../images';
import { SectionWrapper } from '../../../../shared/components/section-wrapper';

export const PortalVideoPreview = () => {
  return (
    <SectionWrapper backgroundColor={colors.slate}>
      <VideoContainer>
        <StyledVideo autoPlay loop muted controls playsInline>
          <source src={portalPreviewVideo} type='video/mp4' />
        </StyledVideo>
      </VideoContainer>
    </SectionWrapper>
  );
};

const VideoContainer = styled.div`
  width: 100%;
  padding: ${spacing.lg};
  padding-bottom: ${spacing.xl};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVideo = styled.video`
  box-sizing: border-box;
  border-radius: 20px;
  width: 900px;
  height: 691px;
`;
