import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../../app/components/styled';
import { happyHourDrinks } from '../../../../images';
import { SectionWrapper } from '../../../../shared/components/section-wrapper';
import { isMobileDevice } from '../../../../shared/helpers/platform';
import { SignupForm } from './signup-form';

export const SignupSection = () => {
  return (
    <SectionWrapper backgroundColor={colors.blue}>
      <Container id='signup-section'>
        <>
          <TextContainer>
            <h1 style={{ color: 'white', marginBottom: 0 }}>
              Boost your footfall for free with Vibe
            </h1>
            <p style={{ color: 'white' }}>
              Showcase your happy hour and events and reach a whole new audience
              with the Vibe portal.
            </p>
          </TextContainer>
          <AbsoluteWrapper>
            <SignupForm />
          </AbsoluteWrapper>
          {!isMobileDevice && (
            <ImageContainer>
              <Image
                alt='A group of people with drinks cheers.'
                src={happyHourDrinks}
              />
            </ImageContainer>
          )}
        </>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  color: white;

  display: flex;
  flex-direction: column;

  @media ${desktopBreakpoint} {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-columns: repeat(12, minmax(0px, 1fr));
    grid-template-rows: 1fr;
  }
`;

const TextContainer = styled.div`
  align-self: end;
  color: ${colors.blue};
  text-align: center;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${desktopBreakpoint} {
    text-align: left;
    grid-area: 2 / 1 / auto / 6;
    padding: 0;
    padding-right: ${spacing.lg};
  }
`;

const AbsoluteWrapper = styled.div`
  @media ${desktopBreakpoint} {
    grid-area: 3 / 2 / 4 / 12;
    max-width: 500px;
    z-index: 2;
    margin-bottom: -50px;
  }
`;

const ImageContainer = styled.div`
  padding: 0;
  grid-column: 1;
  grid-row: 1;
  display: none;

  @media ${desktopBreakpoint} {
    display: block;
    padding: 0;
    grid-area: 2 / 6 / 4 / 13;
  }
`;

const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  height: fit-content;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  width: auto;
  height: 100%;
  max-width: 100%;
  max-height: 600px;
`;
