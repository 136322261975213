import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../app/components/styled';
import { PreSubmissionMessage } from '../../../app/features/shared/components/pre-submission-message';
import { FormSuccessMessage } from '../../shared/components/form-success-message';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { WebActionButton } from '../../shared/components/styled';
import { FeedbackForm } from './components/feedback-form';

const title = 'Got Some Feedback?';
const paragraph = `Notice something off with one of our listings or have feedback to share? Fill out the form below, and we’ll look into it ASAP!`;
const successMessage = `We'll do our best to get back to you within the next 24 hours`;

export const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        {submitted ? (
          <>
            <FormSuccessMessage paragraphTwo={successMessage} />
            <StyledButton onClick={() => setSubmitted(false)}>
              Done
            </StyledButton>
          </>
        ) : (
          <>
            <PreSubmissionMessage title={title} paragraph={paragraph} />
            <FeedbackForm setSubmitted={setSubmitted} />
          </>
        )}
      </Container>
    </SectionWrapper>
  );
};

export default Feedback;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  color: ${colors.blue};
  text-align: center;
  max-width: 600px;
`;

const StyledButton = styled(WebActionButton)`
  && {
    color: ${colors.blue};
  }
`;
