import React, { RefObject, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../app/components/styled';
import { iconAndNameSlate, iconAndNameWhite } from '../../images';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { ButtonLink } from '../../shared/components/styled';

interface Props {
  coverSectionRef?: RefObject<HTMLDivElement>;
}

export const Header = ({ coverSectionRef }: Props) => {
  const [scrolledDown, setScrolledDown] = useState(false);
  const [sectionHeight, setSectionHeight] = useState<number | null>(null);
  const isBusinessPage = window.location.href.includes('business');

  useEffect(() => {
    const scrollListener = () => {
      setScrolledDown(window.scrollY - 100 > 0);
    };

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, [sectionHeight]);

  useEffect(() => {
    if (coverSectionRef?.current) {
      const height =
        coverSectionRef?.current?.getBoundingClientRect().height ?? null;
      setSectionHeight(height);
    }
  }, [coverSectionRef?.current]);

  return (
    <SectionWrapper
      transitionBackground={scrolledDown}
      maxWidth='1800px'
      isSticky={true}
      style={{
        borderBottom: scrolledDown ? `1px ${colors.mediumGrey} solid` : '',
        backgroundColor: scrolledDown ? colors.paleGrey : colors.blue,
      }}
    >
      <ContentWrapper>
        <LogoContainer>
          <Link to={'/'}>
            <Logo
              alt='Vibe logo of a city skyline and the Vibe name'
              src={scrolledDown ? iconAndNameSlate : iconAndNameWhite}
              width='120px'
              height='40px'
            />
          </Link>
        </LogoContainer>
        <ButtonsContainer>
          <ButtonLink to={isBusinessPage ? '/' : '/business/signup'}>
            {isBusinessPage ? 'Get the app' : 'Register Your Business'}
          </ButtonLink>
        </ButtonsContainer>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.md};
  display: flex;
  flex-direction: row;
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
`;

const LogoContainer = styled.div``;

const Logo = styled.img`
  max-width: 120px;

  @media ${desktopBreakpoint} {
    max-width: 180px;
    width: 100%;
    height: 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${spacing.sm};
  margin-left: auto;
`;
