import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../../app/components/styled';
import { sendEmail } from '../../../../api/send-email';
import { PlaceAutoComplete } from '../../../../shared/components/place-auto-complete';
import { WebActionButton } from '../../../../shared/components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledTextArea,
} from '../../../../shared/components/styled-form';
import { TurnstileVerify } from '../../../../shared/components/turnstile-verify';

interface Props {}

export const SignupForm = ({}: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [name, setName] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submit = async () => {
      if (place && place.name) {
        const content = `
Name: ${name}.
Phone: ${phone}.
${email ? `Submitter email: ${email}` : ''}
Business: ${place.name}.
${comment !== undefined ? `Comments: ${comment}` : ''}
  
          `;

        const successful = await sendEmail({
          subject: `New Business Signup - ${place.name}`,
          content,
          turnstileToken,
        });
        if (successful) {
          setSubmitted(true);
        } else {
          setError('There was an error submitting the suggestion.');
        }
      }
    };

    submit();
  };

  return (
    <FormWrapper
      style={{
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: spacing.lg,
        textAlign: 'center',
      }}
    >
      {submitted ? (
        <>
          <p style={{ color: colors.slate }}>
            Thanks for registering! We'll be in touch with you soon.
          </p>
        </>
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <StyledInput
            id='name-input'
            placeholder='Your Name*'
            onChange={(e) => setName(e.target.value)}
            required
          />
          <StyledInput
            id='phone-number-input'
            placeholder='Phone Number*'
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <PlaceAutoComplete setPlace={setPlace} placeholder='Business name*' />
          <StyledInput
            id='email-input'
            placeholder='Email address*'
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <StyledTextArea
            id='comments-input'
            onChange={(e) => setComment(e.target.value)}
            placeholder='Comments'
          />
          {error && <span style={{ color: 'red' }}>{error}</span>}
          {!!turnstileToken ? (
            <StyledButton type='submit'>Register</StyledButton>
          ) : (
            <TurnstileVerify setTurnstileToken={setTurnstileToken} />
          )}
        </StyledForm>
      )}
    </FormWrapper>
  );
};

const StyledButton = styled(WebActionButton)`
  && {
    color: ${colors.blue};

    &:disabled {
      color: ${colors.blue};
    }
  }
`;
