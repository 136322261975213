import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../app/components/styled';
import { friendsCheers } from '../../images';

interface Props {
  paragraphOne?: string;
  paragraphTwo?: string;
}

export const FormSuccessMessage = ({ paragraphOne, paragraphTwo }: Props) => (
  <>
    <Image alt='Friends cheers glasses.' src={friendsCheers} />
    <h1>Cheers to You!</h1>
    <div style={{ color: colors.slate }}>
      <p>
        {paragraphOne ??
          `Thanks for helping make Vibe even better! Your input improves the
        experience for everyone!`}
      </p>
      {paragraphTwo && <p>{paragraphTwo}</p>}
    </div>
  </>
);

const Image = styled.img`
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  border: 2px solid ${colors.paleGrey};
  border-radius: 10px;
  box-shadow: 2px 2px 5px ${colors.mediumGrey};
  max-height: 250px;

  @media ${desktopBreakpoint} {
    max-height: 300px;
  }
`;
