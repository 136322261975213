import React from 'react';
import { isMobileDevice } from '../../../shared/helpers/platform';
import { PortalBenefits } from './components/portal-benefits';
import { PortalCover } from './components/portal-cover';
import { PortalVideoPreview } from './components/portal-video-preview';
import { SignupSection } from './components/signup-section';

export const BusinessSignup = () => {
  return (
    <>
      <SignupSection />
      <PortalBenefits />
      {!isMobileDevice && <PortalVideoPreview />}
      <PortalCover />
    </>
  );
};

export default BusinessSignup;
