import React from 'react';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { LegalTextContainer } from '../../../shared/components/styled';

export const Support = () => {
  return (
    <SectionWrapper>
      <LegalTextContainer>
        <h1>Support</h1>
        <p>For any issues or inquiries please reach out to brian@vibeapp.ca</p>
      </LegalTextContainer>
    </SectionWrapper>
  );
};

export default Support;
