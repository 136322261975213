import AddLocationIcon from '@mui/icons-material/AddLocation';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../app/components/styled';
import { SectionWrapper } from '../../../shared/components/section-wrapper';

export const Benefits = () => {
  return (
    <SectionWrapper backgroundColor={colors.blue}>
      <Container>
        <Benefit>
          <IconWrapper>
            <AddLocationIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>250+ Businesses</h3>
          <p>
            View listings for bars and restaurants across Vancouver, Squamish,
            Whistler and Victoria.
          </p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <RestaurantIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>Menus</h3>
          <p>Explore full food and drink menus for all happy hour offerings.</p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <StarIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>Favourites</h3>
          <p>
            Save your favourite spots to easily find the best deals anytime.
          </p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <MusicNoteIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>Events</h3>
          <p>Browse live events like trivia, music, comedy nights and more.</p>
        </Benefit>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  margin: ${spacing.xl} 0;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: ${spacing.lg};

  @media ${desktopBreakpoint} {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Benefit = styled.div`
  padding: ${spacing.xl};
  background-color: ${colors.paleGrey};
  border-radius: ${spacing.md};
  color: ${colors.slate};
  height: 200px;
  text-align: center;

  @media ${desktopBreakpoint} {
    height: 400px;
  }

  @media (min-width: 1350px) {
    height: 280px;
  }
`;

const IconWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
