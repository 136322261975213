import Button from '@mui/material/Button';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../app/components/styled';

interface Props {
  imgFile: File | undefined | null;
  setImgFile: (file: File | undefined | null) => void;
  Icon: React.ReactNode;
  message: string;
  previewButtonMessage?: string;
  imageMaxWidth?: number;
  imageMaxHeight?: number;
}

export const WebsiteImageInput = ({
  imgFile,
  setImgFile,
  Icon,
  message,
  previewButtonMessage,
  imageMaxWidth,
  imageMaxHeight,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImgFile(e.target.files?.[0]);
  };

  return (
    <PhotoInputWrapper>
      <PhotoIconsWrapper>
        {imgFile ? (
          <ImagePreviewWrapper>
            <ImagePreview
              $imageWidth={imageMaxWidth}
              $imageHeight={imageMaxHeight}
              src={URL.createObjectURL(imgFile)}
              alt='img'
            />
            {previewButtonMessage && (
              <Button onClick={() => fileInputRef.current?.click()}>
                <ButtonWrapper>
                  {Icon}
                  <span>{previewButtonMessage}</span>
                </ButtonWrapper>
              </Button>
            )}
          </ImagePreviewWrapper>
        ) : (
          <Button onClick={() => fileInputRef.current?.click()}>
            <ButtonWrapper>
              {Icon}
              <span>{message}</span>
            </ButtonWrapper>
            <input
              onChange={handleImageUpload}
              multiple={false}
              ref={fileInputRef}
              type='file'
              hidden
            />
          </Button>
        )}
      </PhotoIconsWrapper>
    </PhotoInputWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const ImagePreview = styled.img<{
  $imageWidth?: number;
  $imageHeight?: number;
}>`
  max-width: ${({ $imageWidth }) =>
    $imageWidth ? `${$imageWidth}px` : `100px`};
  ${({ $imageHeight }) => $imageHeight && `max-height: ${$imageHeight}px`};
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PhotoInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhotoIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.lg};
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;
