import React, { useEffect } from 'react';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { spacing } from '../../../app/components/styled';
import { turnstileSiteKey } from '../../../shared-constants';

interface Props {
  setTurnstileToken: (token: string) => void;
}

export const TurnstileVerify = ({ setTurnstileToken }: Props) => {
  useEffect(() => {
    const isDev = window.location.href.includes('localhost');
    if (isDev) {
      setTurnstileToken('dummy token');
    }
  });

  return (
    <TurnstileWrapper>
      <Turnstile
        sitekey={turnstileSiteKey}
        onVerify={(token) => setTurnstileToken(token)}
      />
    </TurnstileWrapper>
  );
};

const TurnstileWrapper = styled.div`
  margin-top: ${spacing.lg};
`;
