import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../../app/components/styled';
import { SectionWrapper } from '../../../../shared/components/section-wrapper';

export const PortalBenefits = () => {
  return (
    <SectionWrapper backgroundColor={colors.slate}>
      <Container>
        <Benefit>
          <IconWrapper>
            <GroupIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>2,000+ Users</h3>
          <p>
            Reach a completely new, rapidly growing audience to help boost your
            footfall.
          </p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <SportsBarIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>Happy Hours</h3>
          <p>Manage your happy hour menu and times.</p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <MusicNoteIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>Events</h3>
          <p>
            Advertise events from trivia and karaoke to paint nights and live
            music.
          </p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <InsightsIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h3>Insights</h3>
          <p>
            View insights into how users are interacting with your listings.
          </p>
        </Benefit>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  margin: ${spacing.xl} 0;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: ${spacing.lg};

  @media ${desktopBreakpoint} {
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Benefit = styled.div`
  padding: ${spacing.xl};
  background-color: ${colors.paleGrey};
  border-radius: ${spacing.md};
  color: ${colors.slate};
  height: 200px;
  text-align: center;

  @media ${desktopBreakpoint} {
    height: 400px;
  }

  @media (min-width: 1350px) {
    height: 280px;
  }
`;

const IconWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
