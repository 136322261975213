import React, { useState } from 'react';
import styled from 'styled-components';
import { sendEmail } from '../../../../app/api';
import { colors, spacing } from '../../../../app/components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledRadioButton,
  StyledTextArea,
} from '../../../../app/features/shared/components/styled-form';
import { PlaceAutoComplete } from '../../../shared/components/place-auto-complete';
import { WebActionButton } from '../../../shared/components/styled';
import { TurnstileVerify } from '../../../shared/components/turnstile-verify';

interface Props {
  setSubmitted: (submitted: boolean) => void;
}

export const FeedbackForm = ({ setSubmitted }: Props) => {
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [comment, setComment] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [owner, setOwner] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const submit = async () => {
      const content = `
${place && place.name ? `Business Name: ${place.name}.` : ''}

Submitter email: ${email}
Business owner: ${owner ? 'yes' : 'no'}.
  
${comment !== undefined ? `Comments: ${comment}` : ''}
  
          `;
      const subject =
        place && place.name ? `Feedback - ${place.name}` : 'General Feedback';

      const successful = await sendEmail({
        subject,
        content,
        turnstileToken,
      });
      if (successful) {
        setSubmitted(true);
      } else {
        setError('There was an error submitting the suggestion.');
      }
    };

    submit();
  };

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit} style={{ marginBottom: spacing.lg }}>
        <StyledLabel htmlFor='comments-input'>Comments*</StyledLabel>
        <StyledTextArea
          id='comments-input'
          onChange={(e) => setComment(e.target.value)}
          required
        />
        <StyledLabel htmlFor='business-name-input'>Business Name</StyledLabel>
        <PlaceAutoComplete
          id='business-name-input'
          placeholder='Business Name'
          setPlace={setPlace}
          required={false}
        />
        <StyledLabel htmlFor='email-input'>Email*</StyledLabel>
        <StyledInput
          id='email-input'
          placeholder='Your Email Address*'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <p>Do You Work for This Business?</p>
        <StyledRadioButton>
          <input
            id='owner-yes'
            type='radio'
            value='Yes'
            checked={owner}
            onChange={() => setOwner(true)}
          />
          <label htmlFor='owner-yes'>Yes</label>
        </StyledRadioButton>
        <StyledRadioButton>
          <input
            id='owner-no'
            type='radio'
            value='No'
            checked={!owner}
            onChange={() => setOwner(false)}
          />
          <label htmlFor='owner-no'>No</label>
        </StyledRadioButton>
        <br />
        <p></p>

        {error && <span style={{ color: 'red' }}>{error}</span>}
        {!!turnstileToken ? (
          <StyledButton type='submit'>Submit</StyledButton>
        ) : (
          <TurnstileVerify setTurnstileToken={setTurnstileToken} />
        )}
      </StyledForm>
    </FormWrapper>
  );
};

const StyledButton = styled(WebActionButton)`
  && {
    color: ${colors.blue};
  }
`;
