import React from 'react';
import { colors } from '../../../components/styled';

interface Props {
  title: string;
  paragraph?: string;
  titleColor?: string;
  paragraphColor?: string;
}

export const PreSubmissionMessage = ({
  title,
  paragraph,
  titleColor = colors.blue,
  paragraphColor = colors.slate,
}: Props) => (
  <>
    <h1 id='suggest' style={{ color: titleColor }}>
      {title}
    </h1>
    {paragraph && (
      <p style={{ color: paragraphColor, marginTop: 0 }}>{paragraph}</p>
    )}
  </>
);
