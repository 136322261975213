// Images
export const appPreview =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215214/website/app-phones-preview.webp';
export const appStoreButton =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215219/website/app-store-button.webp';
export const googlePlayButton =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215219/website/google-play-button.webp';
export const iconAndNameWhite =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215214/website/logo-and-name-white.webp';
export const iconAndNameSlate =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1739215214/website/logo-and-name-slate.webp';
export const phoneFrame =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1741291010/website/pykqx8oaporxkwitavl8.png';
export const portalPreview =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1742864344/website/vpfkxjvslopkh6nntduq.png';
export const happyHourDrinks =
  'https://images.unsplash.com/photo-1575037614876-c38a4d44f5b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
export const friendsCheers =
  'https://res.cloudinary.com/duwovvtle/image/upload/v1743024688/website/wnnnt0xonch8gsnahnlc.jpg';
export const donnellansIcon = 'https://i.ibb.co/92rMBWq/donnellans.webp';
export const hynesIcon = 'https://i.ibb.co/6Nttxvn/hynes.png';
export const smithsIcon = 'https://i.ibb.co/yBD3sFL/smiths.webp';
export const theRavenIcon = 'https://i.ibb.co/bs9pNCT/the-raven.webp';
export const theShamrockIcon = 'https://i.ibb.co/Cw4YXqf/the-shamrock.webp';

// Videos
export const appPreviewVideo =
  'https://res.cloudinary.com/duwovvtle/video/upload/v1741295059/website/fndt6fxnoyeqwjdn0w9z.mp4';
export const portalPreviewVideo =
  'https://res.cloudinary.com/duwovvtle/video/upload/v1742866424/website/tolpndmiujwpva2hiusd.mp4';
