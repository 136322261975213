import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';

import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../../app/components/styled';
import { portalPreview } from '../../../../images';
import { SectionWrapper } from '../../../../shared/components/section-wrapper';
import { WebActionButton } from '../../../../shared/components/styled';
import { StyledParagraph } from './styled';

export const PortalCover = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const nameInput: HTMLInputElement | null =
      window.document.querySelector('#name-input');

    if (nameInput) {
      nameInput.focus();
    }
  };

  return (
    <SectionWrapper ref={ref} backgroundColor={colors.paleGrey}>
      <GridContainer>
        <TextContainer>
          <h1>Reach a new audience today</h1>
          <StyledParagraph style={{ color: colors.slate, marginTop: 0 }}>
            Showcase your happy hour details and live events.
          </StyledParagraph>
          <ButtonsContainer>
            <StyledButton onClick={handleClick}>Register Now</StyledButton>
          </ButtonsContainer>
        </TextContainer>
        <ImageContainer>
          <Image
            alt='Preview of the Vibe web portal for managing happy hour and event listings.'
            src={portalPreview}
          />
        </ImageContainer>
      </GridContainer>
    </SectionWrapper>
  );
});

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;

  @media ${desktopBreakpoint} {
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
    padding-top: ${spacing.lg};
    padding-bottom: ${spacing.lg};
    margin-bottom: ${spacing.lg};
  }
`;

const TextContainer = styled.div`
  align-self: end;
  color: ${colors.blue};
  text-align: center;
  grid-column: 1;
  grid-row: 2;
  padding-bottom: ${spacing.xl};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${desktopBreakpoint} {
    text-align: left;
    grid-column: 1;
    grid-row: 1;
    padding: ${spacing.xl};
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  padding: 0;
  grid-column: 1;
  grid-row: 1;
  display: flex;

  @media ${desktopBreakpoint} {
    padding: 0;
    grid-column: 2;
    grid-row: 1;
  }
`;

const Image = styled.img`
  object-fit: contain;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  border: 2px solid ${colors.paleGrey};
  border-radius: 10px;
  box-shadow: 3px 3px 8px ${colors.mediumGrey};

  @media ${desktopBreakpoint} {
    width: 80%;
    max-width: 700px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: ${spacing.md};

  @media ${desktopBreakpoint} {
    margin-top: ${spacing.xl};
  }
`;

const StyledButton = styled(WebActionButton)`
  && {
    background-color: ${colors.slate};
    padding: ${spacing.md} ${spacing.lg};
  }
`;
