import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import { PlaceType } from '../../types';
import { StyledInput } from './styled-form';

interface Props {
  id?: string;
  setPlace: (place: google.maps.places.PlaceResult | null) => void;
  placeholder: string;
  required?: boolean;
}

export const PlaceAutoComplete = ({
  id,
  setPlace,
  placeholder,
  required = true,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const placesLib = useMapsLibrary('places');
  const [autocomplete, setAutocomplete] = useState<PlaceType | null>(null);

  useEffect(() => {
    if (!placesLib || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      types: ['establishment'],
      componentRestrictions: { country: 'can' },
      fields: ['name'],
    };

    setAutocomplete(new placesLib.Autocomplete(inputRef.current, options));
  }, [placesLib]);

  useEffect(() => {
    if (!autocomplete) return;

    autocomplete.addListener('place_changed', () => {
      setPlace(autocomplete.getPlace());
    });
  }, [autocomplete]);

  return (
    <StyledInput
      id={id ?? 'place-input'}
      ref={inputRef}
      placeholder={placeholder}
      required={required}
    />
  );
};
