import React from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../app/components/styled';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { WebActionButton } from '../../../shared/components/styled';

export const CtaButtonSection = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <SectionWrapper backgroundColor={colors.blue}>
      <Container>
        <StyledButton onClick={handleClick}>
          Get the app now for free
        </StyledButton>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.xl};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.blue};
  width: 100%;
`;

const StyledButton = styled(WebActionButton)`
  && {
    background-color: ${colors.slate};
  }
`;
