import styled from 'styled-components';
import {
  desktopBreakpoint,
  fontSize,
} from '../../../../../app/components/styled';

export const StyledParagraph = styled.p`
  @media ${desktopBreakpoint} {
    font-size: ${fontSize.textLarge};
  }
`;
