import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../app/components/styled';
import { PreSubmissionMessage } from '../../../../app/features/shared/components/pre-submission-message';
import { FormSuccessMessage } from '../../../shared/components/form-success-message';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { WebActionButton } from '../../../shared/components/styled';
import { SuggestionForm } from './suggestion-form';

const title = 'Help grow Vibe!';
const paragraph = `Whether it's a cozy corner pub or a trendy rooftop bar with an unbeatable
      happy hour or exciting live events, we’d love to add it to our list!`;
const successMessage = `We'll do our best to have your suggestion listed in the next 24 hours!`;

export const Suggestion = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        {submitted ? (
          <>
            <FormSuccessMessage paragraphTwo={successMessage} />
            <StyledButton onClick={() => setSubmitted(false)}>
              Submit Another
            </StyledButton>
          </>
        ) : (
          <>
            <PreSubmissionMessage title={title} paragraph={paragraph} />
            <SuggestionForm setSubmitted={setSubmitted} />
          </>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  color: ${colors.blue};
  text-align: center;
  max-width: 600px;
`;

const StyledButton = styled(WebActionButton)`
  && {
    color: ${colors.blue};
  }
`;
