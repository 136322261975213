import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../app/components/styled';

export const LegalTextContainer = styled.div`
  margin-bottom: ${spacing.lg};
  align-self: end;
  color: ${colors.slate};
  padding: 0 ${spacing.xl};

  @media ${desktopBreakpoint} {
    padding: ${spacing.xl};
    width: 80%;
    padding-right: 0;
  }
`;

export const WebActionButton = styled(Button)`
  && {
    color: white;
    padding: ${spacing.lg} ${spacing.lg};
    width: fit-content;
    border-radius: 10px;
    z-index: 0;
    font-size: 1em;

    @media ${desktopBreakpoint} {
      font-size: 1.2em;
      letter-spacing: 1;
    }
  }
`;

export const ButtonLink = styled(Link)`
  && {
    color: white;
    background-color: ${colors.slate};
    text-decoration: none;
    padding: ${spacing.md} ${spacing.lg};
    border-radius: 10px;
    font-size: 0.8em;
    min-height: 20px;
    display: flex;
    align-items: center;

    @media ${desktopBreakpoint} {
      font-size: 1em;
    }

    &&:focus,
    &&:active {
      background-color: ${colors.slate};
      outline: none;
    }
  }
`;
