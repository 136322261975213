import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../app/components/styled';
import { appPreviewVideo, phoneFrame } from '../../../images';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { StyledParagraph } from './styled';

export const AppVideoPreview = () => {
  return (
    <SectionWrapper backgroundColor={colors.slate}>
      <GridContainer>
        <TextContainer>
          <h1>See Vibe in action</h1>
          <StyledParagraph style={{ color: 'white', marginTop: 0 }}>
            Discover how Vibe makes finding the best happy hours and local
            events a breeze.
          </StyledParagraph>
          <StyledList>
            <StyledListItem>
              Filter by happy hours on now, coming up soon or at specific times
            </StyledListItem>
            <StyledListItem>
              View upcoming events like trivia at your locals
            </StyledListItem>
            <StyledListItem>Filter events by category or date</StyledListItem>
            <StyledListItem>Save your favourites</StyledListItem>
          </StyledList>
        </TextContainer>
        <VideoContainer>
          <VideoBackgroundContainer>
            <StyledVideo autoPlay loop muted controls playsInline>
              <source src={appPreviewVideo} type='video/mp4' />
            </StyledVideo>
          </VideoBackgroundContainer>
        </VideoContainer>
      </GridContainer>
    </SectionWrapper>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: ${spacing.lg};
  padding-bottom: ${spacing.xl};

  @media ${desktopBreakpoint} {
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const TextContainer = styled.div`
  color: white;
  text-align: center;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;

  @media ${desktopBreakpoint} {
    text-align: left;
    grid-column: 2;
    grid-row: 1;
    padding: ${spacing.xl};
    padding-right: 0;
  }
`;

const VideoContainer = styled.div`
  padding: ${spacing.lg};
  padding-bottom: 0;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  justify-content: center;

  @media ${desktopBreakpoint} {
    padding: ${spacing.lg};
    grid-column: 1;
    grid-row: 1;
  }
`;

const VideoBackgroundContainer = styled.div`
  background-image: url(${phoneFrame});
  background-size: cover;
  width: 230px;
  height: 475px;

  @media ${desktopBreakpoint} {
    width: 300px;
    height: 615px;
  }
`;

const StyledVideo = styled.video`
  box-sizing: border-box;
  width: 210px;
  height: 455px;
  margin-left: 11px;
  margin-top: 10px;
  border-radius: 25px;

  @media ${desktopBreakpoint} {
    width: 270px;
    height: 590px;
    margin-left: 15px;
    margin-top: 12px;
    border-radius: 35px;
  }
`;

const StyledList = styled.ul`
  display: none;

  @media ${desktopBreakpoint} {
    display: block;
  }
`;

const StyledListItem = styled.li`
  color: white;
  margin-top: ${spacing.sm};
  margin-bottom: 0;
  font-size: 1.25em;
`;
