import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import styled from 'styled-components';
import { sendEmail } from '../../../../app/api';
import { colors, spacing } from '../../../../app/components/styled';
import { PlaceAutoComplete } from '../../../shared/components/place-auto-complete';
import { WebActionButton } from '../../../shared/components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledRadioButton,
  StyledTextArea,
} from '../../../shared/components/styled-form';
import { TurnstileVerify } from '../../../shared/components/turnstile-verify';
import { WebsiteImageInput } from '../../../shared/components/website-image-input';
import { isMobileDevice } from '../../../shared/helpers/platform';

interface Props {
  setSubmitted: (submitted: boolean) => void;
}

export const SuggestionForm = ({ setSubmitted }: Props) => {
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [link, setLink] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [owner, setOwner] = useState(false);
  const [imgFile, setImgFile] = useState<File | undefined | null>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);
    e.preventDefault();
    const submit = async () => {
      if (place && place.name) {
        const content = `
Name: ${place.name}.

${email ? `Submitter email: ${email}` : ''}
Image attached: ${!!imgFile ? 'Yes' : 'No'}.
Business owner: ${owner ? 'yes' : 'no'}.
  
${link !== undefined ? `Menu URL: ${link}` : ''}

${comment !== undefined ? `Comments: ${comment}` : ''}
  
          `;

        const successful = await sendEmail({
          subject: `Pub Suggestion - ${place.name}`,
          content,
          file: imgFile,
          turnstileToken,
        });
        if (successful) {
          setSubmitted(true);
        } else {
          setError('There was an error submitting the suggestion.');
        }
        setSubmitting(false);
      }
    };

    submit();
  };

  return (
    <FormWrapper>
      <StyledForm
        onSubmit={handleSubmit}
        style={{ marginBottom: isMobileDevice ? 0 : spacing.lg }}
      >
        <PlaceAutoComplete placeholder='Business name*' setPlace={setPlace} />
        <StyledInput
          id='email-input'
          placeholder='Your email address'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
        />
        <p>Do you work for this business?</p>
        <StyledRadioButton>
          <input
            id='owner-yes'
            type='radio'
            value='Yes'
            checked={owner}
            onChange={() => setOwner(true)}
          />
          <label htmlFor='owner-yes'>Yes</label>
        </StyledRadioButton>
        <StyledRadioButton>
          <input
            id='owner-no'
            type='radio'
            value='No'
            checked={!owner}
            onChange={() => setOwner(false)}
          />
          <label htmlFor='owner-no'>No</label>
        </StyledRadioButton>
        <br />
        <p></p>

        {!isMobileDevice && (
          <>
            <StyledInput
              id='menu-link-input'
              placeholder='Menu link'
              onChange={(e) => setLink(e.target.value)}
            />
          </>
        )}
        <StyledTextArea
          id='comments-input'
          onChange={(e) => setComment(e.target.value)}
          placeholder='Comments'
        />
        <ImageWrapper>
          {isMobileDevice && (
            <WebsiteImageInput
              imgFile={imgFile}
              setImgFile={setImgFile}
              Icon={<AddAPhotoIcon />}
              message='Capture Menu'
            />
          )}
        </ImageWrapper>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        {!!turnstileToken ? (
          <StyledButton type='submit' disabled={submitting}>
            {submitting ? <StyledCircularProgress size={25} /> : 'Submit'}
          </StyledButton>
        ) : (
          <TurnstileVerify setTurnstileToken={setTurnstileToken} />
        )}
      </StyledForm>
    </FormWrapper>
  );
};

const StyledButton = styled(WebActionButton)`
  && {
    color: ${colors.blue};
  }
`;

const ImageWrapper = styled.div`
  svg {
    color: ${colors.blue};
  }

  span {
    color: ${colors.blue};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: ${spacing.sm} ${spacing.lg};
`;
